import './App.css';
import { Map, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import React, { useState, useEffect } from 'react';
import MapMarker from './planeIcon.svg'
import AirportIcon from './airport.svg'
import { Icon } from 'leaflet'
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";

import axios from 'axios'

import RotatedMarker from "./RotatedMarker";
import PopUpFlightInfo from './PopUpFlightInfo';

/*

  Plane Icon Importer

*/

import DLH from "./PlaneIcons/planeIcon_DLH.svg"
import ACA from "./PlaneIcons/planeIcon_ACA.svg"
import AIC from "./PlaneIcons/planeIcon_AIC.svg"
import AXB from "./PlaneIcons/planeIcon_AXB.svg"
import AEE from "./PlaneIcons/planeIcon_AEE.svg"
import ANA from "./PlaneIcons/planeIcon_ANA.svg"
import AVA from "./PlaneIcons/planeIcon_AVA.svg"
import ANZ from "./PlaneIcons/planeIcon_ANZ.svg"
import AUA from "./PlaneIcons/planeIcon_AUA.svg"
import BEL from "./PlaneIcons/planeIcon_BEL.svg"
import BCS from "./PlaneIcons/planeIcon_BCS.svg"
import AWI from "./PlaneIcons/planeIcon_AWI.svg"
import BOX from "./PlaneIcons/planeIcon_BOX.svg"
import CCA from "./PlaneIcons/planeIcon_CCA.svg"
import DLA from "./PlaneIcons/planeIcon_DLA.svg"
import EDW from "./PlaneIcons/planeIcon_EDW.svg"
import EWG from "./PlaneIcons/planeIcon_EWG.svg"
import GEC from "./PlaneIcons/planeIcon_GEC.svg"
import LOT from "./PlaneIcons/planeIcon_LOT.svg"
import UCA from "./PlaneIcons/planeIcon_UCA.svg"
import MSR from "./PlaneIcons/planeIcon_MSR.svg"
import GLG from "./PlaneIcons/planeIcon_GLG.svg"
import DHK from "./PlaneIcons/planeIcon_DHK.svg"
import OCN from "./PlaneIcons/planeIcon_OCN.svg"
import LRC from "./PlaneIcons/planeIcon_LRC.svg"
import SAS from "./PlaneIcons/planeIcon_SAS.svg"
import FDX from "./PlaneIcons/planeIcon_FDX.svg"
import GJS from "./PlaneIcons/planeIcon_GJS.svg"
import SZS from "./PlaneIcons/planeIcon_SZS.svg"
import RPA from "./PlaneIcons/planeIcon_RPA.svg"
import SIA from "./PlaneIcons/planeIcon_SIA.svg"
import CSZ from "./PlaneIcons/planeIcon_CSZ.svg"
import SWR from "./PlaneIcons/planeIcon_SWR.svg"
import THD from "./PlaneIcons/planeIcon_THD.svg"
import SKW from "./PlaneIcons/planeIcon_SKW.svg"
import NCA from "./PlaneIcons/planeIcon_NCA.svg"
import CTN from "./PlaneIcons/planeIcon_CTN.svg"
import RPB from "./PlaneIcons/planeIcon_RPB.svg"
import LLR from "./PlaneIcons/planeIcon_LLR.svg"
import SAA from "./PlaneIcons/planeIcon_SAA.svg"
import ASH from "./PlaneIcons/planeIcon_ASH.svg"
import TAI from "./PlaneIcons/planeIcon_TAI.svg"
import SXD from "./PlaneIcons/planeIcon_SXD.svg"
import ROU from "./PlaneIcons/planeIcon_ROU.svg"
import SXS from "./PlaneIcons/planeIcon_SXS.svg"
import CLH from "./PlaneIcons/planeIcon_CLH.svg"
import TAP from "./PlaneIcons/planeIcon_TAP.svg"
import EVA from "./PlaneIcons/planeIcon_EVA.svg"
import IBB from "./PlaneIcons/planeIcon_IBB.svg"
import THA from "./PlaneIcons/planeIcon_THA.svg"
import THY from "./PlaneIcons/planeIcon_THY.svg"
import AAR from "./PlaneIcons/planeIcon_AAR.svg"
import PAC from "./PlaneIcons/planeIcon_PAC.svg"
import EWE from "./PlaneIcons/planeIcon_EWE.svg"
import UIA from "./PlaneIcons/planeIcon_UIA.svg"
import JZA from "./PlaneIcons/planeIcon_JZA.svg"
import TPA from "./PlaneIcons/planeIcon_TPA.svg"
import TOUR from "./PlaneIcons/planeIcon_TOUR.svg"
import UAL from "./PlaneIcons/planeIcon_UAL.svg"
import ETH from "./PlaneIcons/planeIcon_ETH.svg"
import CAO from "./PlaneIcons/planeIcon_CAO.svg"
import UPS from "./PlaneIcons/planeIcon_UPS.svg"
import OAW from "./PlaneIcons/planeIcon_OAW.svg"
import MSX from "./PlaneIcons/planeIcon_MSX.svg"
import OAL from "./PlaneIcons/planeIcon_OAL.svg"
import CMP from "./PlaneIcons/planeIcon_CMP.svg"

const MapMarkerAirportIcon = new Icon({
  iconUrl: AirportIcon,
  iconSize: [20, 20]
});

function App() {

  const queryParams = new URLSearchParams(window.location.search);
  
  const MapSource = queryParams.get('source');

  const [ loading, setLoading ] = useState(true)

  const [ flights, setFlights ] = useState([])

  const [ center, setCenter ] = useState()

  const getFlights = () => {

    axios.get(
      `${MapSource}/vam/get_live_flights.php`
      //`https://virtuallh.com/vam/get_live_flights.php`
    ).then(function (response) {
      setFlights(response.data)
      setLoading(false)
    });

  }

  useEffect(() => {

    getFlights()
    
    setInterval(function(){ 

      getFlights()

    }, 500000);

  }, [])

  const selectFlight = (flight) => {

    const flightsArray = [...flights]

    const position = flightsArray.findIndex((flightsCopy) => flightsCopy?.flight_id === flight?.flight_id)

    const handler = flightsArray[position].displayData

    flightsArray.map((flight) => {

      return (

        flight.displayData = false

      )

    })

    handler ? flightsArray[position].displayData = false : flightsArray[position].displayData = !flightsArray[position].displayData

    setFlights(flightsArray)

  }

  const ClearFlight = () => {

    const flightsArray = [...flights]

    flightsArray.map((flight) => {

      return (

        flight.displayData = false

      )

    })

    setFlights(flightsArray)

  }

  useEffect(() => {

    if(flights.findIndex((flightsCopy) => flightsCopy?.displayData) !== -1) {

      const position = flights.findIndex((flightsCopy) => flightsCopy?.displayData)

      const center = []

      center.push(flights[position]?.latitude)

      center.push(flights[position]?.longitude)

      setCenter(center)

    }

  }, [flights])

  const getCurve = (positions) => {

    const line = helpers.lineString(positions.map(latLng => [parseFloat(latLng[1]),parseFloat(latLng[0])]));
  
    const curved = bezierSpline(line);

    return curved

  }

  const returnPlaneIcon = (airlineIcao, colorid) => {

    var icon = MapMarker

    switch(airlineIcao) {
      case 'DLH':
        icon = DLH;
        break;
      case 'GEC':
        icon = GEC;
        break;
      case 'BOX':
        icon = BOX;
        break;
      case 'DLA':
        icon = DLA;
        break;
      case 'EWG':
        icon = EWG;
        break;
      case 'SWR':
        icon = SWR;
        break;
      case 'AUA':
        icon = AUA;
        break;
      case 'BEL':
        icon = BEL;
        break;
      case 'EDW':
        icon = EDW;
        break;
      case 'SXS':
        icon = SXS;
        break;
      case 'ANA':
        icon = ANA;
        break;
      case 'ACA':
        icon = ACA;
        break;
      case 'UAL':
        icon = UAL;
        break;
      case 'SIA':
        icon = SIA;
        break;
      case 'CCA':
        icon = CCA;
        break;
      case 'TOUR':
        icon = TOUR;
        break;
      case 'LOT':
        icon = LOT;
        break;
      case 'AIC':
        icon = AIC;
        break;
      case 'TAP':
        icon = TAP;
        break;
      case 'THA':
        icon = THA;
        break;
      case 'ANZ':
        icon = ANZ;
        break;        
      case 'MSR':
        icon = MSR;
        break;        
      case 'SAS':
        icon = SAS;
        break;
      case 'THY':
        icon = THY;
        break;
      case 'UPS':
        icon = UPS;
        break;
      case 'AVA':
        icon = AVA;
        break;
      case 'AEE':
        icon = AEE;
        break;
      case 'OAL':
        icon = OAL;
        break;
      case 'AXB':
        icon = AXB;
        break;
      case 'LLR':
        icon = LLR;
        break;
      case 'LRC':
        icon = LRC;
        break;
      case 'GLG':
        icon = GLG;
        break;
      case 'TAI':
        icon = TAI;
        break;
      case 'TPA':
        icon = TPA;
        break;
      case 'FDX':
        icon = FDX;
        break;
      case 'BCS':
        icon = BCS;
        break;
      case 'DHK' || 'DHX' || 'DAE' || 'TMN' || 'JOS' || 'BDA' || 'AHK' :
        icon = DHK;
        break;
      case 'PAC':
        icon = PAC;
        break;
      case 'THD':
        icon = THD;
        break;
      case 'SZS':
        icon = SZS;
        break;
      case 'OCN':
        icon = OCN;
        break;
      case 'AWI':
        icon = AWI;
        break;
      case 'UCA':
        icon = UCA;
        break;
      case 'GJS':
        icon = GJS;
        break;
      case 'RPA':
        icon = RPA;
        break;
      case 'SKW':
        icon = SKW;
        break;
      case 'ASH':
        icon = ASH;
        break;
      case 'AAR':
        icon = AAR;
        break;
      case 'CMP':
        icon = CMP;
        break;
      case 'RPB':
        icon = RPB;
        break;
      case 'CTN':
        icon = CTN;
        break;
      case 'ETH':
        icon = ETH;
        break;
      case 'EVA':
        icon = EVA;
        break;
      case 'UIA':
        icon = UIA;
        break;
      case 'CSZ':
        icon = CSZ;
        break;
      case 'SAA':
        icon = SAA;
        break;
      case 'IBB':
        icon = IBB;
        break;
      case 'OAW':
        icon = OAW;
        break;
      case 'NCA':
        icon = NCA;
        break;
      case 'JZA':
        icon = JZA;
        break;
      case 'ROU':
        icon = ROU;
        break;
      case 'CLH':
        icon = CLH;
        break;
      case 'EWE':
        icon = EWE;
        break;
      case 'CAO':
        icon = CAO;
        break;
      case 'MSX':
        icon = MSX;
        break;

      case 'SXD':
        icon = SXD;
        break;
    }

    console.log(airlineIcao, icon)

    const MapMarkerIcon = new Icon({
      iconUrl: icon,
      iconSize: [40, 40]
    });

    return MapMarkerIcon

  }

  return (

    loading ?

      <>LOADING...</>

    : 

      flights.length === 0 ?

        <>No flights to track</>

      :

        <Map center={center ? center : [flights[0]?.latitude, flights[0]?.longitude]} zoom={5} scrollWheelZoom={false} style={{width: '100%', height: '100%', position: "absolute"}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"target="_blank">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          />

            {

              flights.map((flight, index) => {

                return (

                  <>

                    {

                      flight.displayData &&

                        <Marker key={flight.flight_id+index} position={[parseFloat(flight.dep_lat), parseFloat(flight.dep_lon)]} icon={MapMarkerAirportIcon}>
                          
                          <Popup>
                            
                            {/*<img style={{width: '300px', height: '150px', borderRadius: '12px 12px 0 0', objectFit: 'cover'}} src={`https://source.unsplash.com/1600x900/?${flight.dep_municipality}`} />
                            <br /> 
                            <h3 style={{textAlign: 'center'}}>{flight.dep_name}</h3>
                            */}
                            {flight.dep_name}
                            
                          </Popup>                

                        </Marker>

                    }

                    <RotatedMarker
                      key={index}
                      position={[parseFloat(flight.latitude), parseFloat(flight.longitude)]}
                      rotationAngle={flight.heading}
                      icon={returnPlaneIcon(flight.icao, flight.colorid)}
                      onClick={() => selectFlight(flight)}
                    >

                      {

                        flight.displayData &&

                          <>
                      
                            <PopUpFlightInfo flight={flight} ClearFlight={ClearFlight} />
                        
                            <GeoJSON
                              key={flight.flight_id}
                              data={getCurve([[flight.dep_lat, flight.dep_lon], [flight.latitude, flight.longitude]])}
                            />
                        
                            <GeoJSON
                              key={flight.flight_id}
                              data={getCurve([[flight.latitude, flight.longitude], [flight.arr_lat, flight.arr_lon]])}
                            />

                          </>

                      }

                    </RotatedMarker>

                    {

                      flight.displayData &&

                        <Marker position={[parseFloat(flight.arr_lat), parseFloat(flight.arr_lon)]} icon={MapMarkerAirportIcon}>
                          
                          <Popup>
                            
                            {/*<img style={{width: '300px', height: '150px', borderRadius: '12px 12px 0 0', objectFit: 'cover'}} src={`https://source.unsplash.com/1600x900/?${flight.arr_municipality}`} />
                            <br /> 
                            <h3 style={{textAlign: 'center'}}>{flight.arr_name}</h3>
                            */}
                            {flight.arr_name}
                            
                          </Popup>                

                        </Marker>
                    
                    }

                  </>

                )

              })

            }
        </Map>

  );
}

export default App;